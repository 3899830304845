import Form, { formCreate } from '@/found/components/form';

import ItemRespVos from './product_info.vue';
import PhotoRespVos from './photo_list.vue';

formCreate.component('ItemRespVos', ItemRespVos);
formCreate.component('PhotoRespVos', PhotoRespVos);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'physical_promotion_form',
    };
  },
  created() {

  },
  methods: {
    formComplete() {
      if (this.formConfig.code === 'view') {
        const tempData = this.formConfig.row;
        this.setValue(tempData);
        if (tempData && tempData.itemRespVos && tempData.itemRespVos.length !== 0) {
          this.hiddenFields(false, ['itemRespVos', 'title3']);
        } else {
          this.hiddenFields(true, ['itemRespVos', 'title3']);
        }
        if (tempData && tempData.photoRespVos && tempData.photoRespVos.length !== 0) {
          this.hiddenFields(false, ['photoRespVos', 'title2']);
        } else {
          this.hiddenFields(true, ['photoRespVos', 'title2']);
        }
      }
    },
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'itemRespVos') {
        v.props.value = this.formConfig.row.itemRespVos || [];
      }
      if (v.field === 'photoRespVos') { // 选择预算科目
        v.props.value = this.formConfig.row.PhotoRespVos || [];
      }
      return v;
    },
  },
};
