var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: { data: _vm.tableData, "max-height": "500px" },
        },
        [
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "itemName", title: "物品名称" },
          }),
          _c("vxe-table-column", {
            attrs: { "min-width": "100", field: "itemNum", title: "物品数量" },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "itemSinglePrice",
              title: "物品价格",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }