import TablePage from '@/found/components/table_page';
import Form from './form/index';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('physical_promotion_list');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formName = 'Form';
        this.modalConfig.title = '详情';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.openFull();
      }
    },
  },

};
