<template>
  <div class="img-wrap">
    <div v-for="(item,index) in imgList" :key="index">
      <el-image
        style="width: 100px; height: 100px;margin-right:20px"
        :src="item"
        :preview-src-list="imgList">
      </el-image>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    value: Array,
  },
  watch: {
    value() {
      const temp = [];
      for (const item of this.value) {
        item.photos.split(',').forEach((list) => {
          temp.push(list);
        });
      }
      this.imgList = temp;
    },
  },
  data() {
    return {
      imgList: [],
    };
  },

  methods: {},
};
</script>
<style scoped>
.img-wrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
   margin-bottom: 20px;
}
</style>
